<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2 d-flex align-items-center">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :text="avatarText(userData.prenom + ' ' + userData.nom)"
          :variant="`light-${resolveUserRoleVariant(userData.roles)}`"
          size="60px"
          rounded
        />
      </template>
      <div class="d-flex flex-column justify-content-center">
        <h4 class="mb-0">
          {{ userData.prenom + ' ' + userData.nom }} <span class="text-muted mb-0 font-small-4">({{ userData.username }})</span>
        </h4>
        <div class="d-flex flex-wrap mb-0">
          <h6 class="text-muted mb-0">
            {{ userData.email }}
          </h6>
        </div>
      </div>
    </b-media>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="username"
              rules="required"
            >
              <b-form-group
                label="Identifiant de connexion"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="userData.username"
                  disabled="disabled"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="nom"
              rules="required"
            >
              <b-form-group
                label="Nom"
                label-for="nom"
              >
                <b-form-input
                  id="nom"
                  v-model="userData.nom"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Prénom"
              rules="required"
            >
              <b-form-group
                label="Prénom"
                label-for="prenom"
              >
                <b-form-input
                  id="prenom"
                  v-model="userData.prenom"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Groupe"
              label-for="user-role"
            >
              <v-select
                v-model="userData.roles"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                label="label"
                :clearable="false"
                input-id="user-role"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="email"
              rules="required"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  disabled="disabled"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <!-- Field: Statut -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Statut"
              label-for="user-status"
            >
              <v-select
                v-model="userData.published"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Sauvegarder les modifications
        </b-button>
        <b-button
          variant="outline-secondary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Annuler
        </b-button>
      </b-form>
    </validation-observer>
    <!-- PERMISSION TABLE -->
    <!--    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card>-->

    <!-- Action Buttons -->
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import { useRouter } from '@core/utils/utils'
import useStatutsList from '../statuts-list/useStatutsList'
import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props) {
    const toast = useToast()
    const { router } = useRouter()

    const { resolveUserRoleVariant } = useStatutsList()

    const roleOptions = [
      { label: 'Administrateur', value: 'ROLE_ADMIN' },
      { label: 'Maintenance', value: 'ROLE_MAINTENANCE' },
      { label: 'Utilisateur', value: 'ROLE_USER' },
    ]

    const statusOptions = [
      { label: 'Actif', value: true },
      { label: 'Désactivé', value: false },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    const resetuserData = () => {
      router.push('/administration/utilisateurs')
    }

    const onSubmit = () => {
      store.dispatch('app-user/editUser', props.userData)
        .then(() => {
          resetuserData()
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Utilisateur modifié avec succès',
              variant: 'success',
              text: '',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      router,

      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
